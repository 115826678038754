import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import DuplicationBtnComponent from '../../shared/duplication/open_modal_for_input_next_year';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.lr_skill_sets.index'),

  ui: {
    duplication: '.js-duplication'
  },

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    new DuplicationBtnComponent({ el: this.ui.duplication }).render();
    this.initDataTables({
      columns : this.columns(),
      order   : [[0, 'desc'], [1, 'desc'], [2, 'asc'], [3, 'asc']],
      rowGroup: {
        dataSrc: (row) => {
          return `${row.curriculum_year} ${row.entry_term}`;
        }
      }
    });
  },

  columns() {
    return [
      {
        class  : 'curriculum_year',
        data   : 'curriculum_year',
        visible: false
      },
      {
        class  : 'entry_term',
        data   : 'entry_term',
        visible: false
      },
      {
        class   : 'license_type',
        data    : 'license_type',
        width   : 80,
        sortable: false
      },
      {
        class   : 'license',
        data    : 'license',
        sortable: false
      },
      {
        class   : 'answers_count text-right',
        data    : 'answers_count',
        sortable: false
      },
      {
        class   : 'text-center',
        data    : 'show_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      },
      {
        class   : 'text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'text-center',
        data    : 'show_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.answers_count !== '0') return '';
          return Utils.Render.deleteButton(this, `${val}.json`);
        }
      }
    ];
  }
}));
