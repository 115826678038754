import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.import_histories.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      order  : [[0, 'desc']],
      columns: this.columns()
    });
  },

  columns() {
    return [
      {
        class  : 'created_at',
        data   : 'created_at',
        visible: false
      },
      {
        class: 'processed_at',
        data : 'processed_at',
        width: 130
      },
      {
        class: 'type',
        data : 'type'
      },
      {
        class: 'mode',
        data : 'mode'
      },
      {
        class: 'all_num text-right',
        data : 'all_num',
        width: 100
      },
      {
        class: 'imported_num text-right',
        data : 'imported_num',
        width: 100
      },
      {
        class: 'illegal_num text-right',
        data : 'illegal_num',
        width: 100
      },
      {
        class: 'skipped_num text-right',
        data : 'skipped_num',
        width: 100
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 80
      },
      {
        class: 'run_time text-right',
        data : 'run_time',
        width: 80
      },
      {
        class   : 'log_link text-center',
        data    : 'log_path',
        sortable: false,
        width   : 80,
        render  : (val, _, row) => {
          if (!val) return '';
          // status の label-xxx からコンテキストカラーを抽出
          const statusMatch = String(row.status).match(/label-([\w-]+)/);
          const statusType = statusMatch ? statusMatch[1] : 'default';

          return Utils.Render.linkTemplate({
            linkClass: `btn btn-xs btn-${statusType}`,
            iconClass: 'fa-share',
            text     : this.t('.log_link'),
            href     : val
          });
        }
      }
    ];
  }
}));
